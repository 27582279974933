import styled from 'styled-components';
import { Par } from '../components/library/Par';
import { Heading } from '../components/library/Heading';

const Wrapper = styled.div`
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: center;
  height: 100%;
  width: 80%;
  padding-bottom: 1rem;
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: center;
  height: 100%;
  width: 100%;
  gap: 1rem;
`;

const ContentWrapperTwo = styled.div`
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: center;
  height: 100%;
  width: 100%;
  gap: 1.5rem;
`;

const aboutContent = [
  {
    title: 'The Legend of The Wolfbadger',
    content: [
      'In the dense, ancient forests of Eldergrove, where the towering trees whisper tales of old, there exists a creature both fierce and noble, a guardian of balance and protector of the wild. This creature is known as the Wolfbadger.',
    ],
  },
  {
    title: 'Origins',
    content: [
      'The legend of the Wolfbadger dates back centuries to a time when the land was plagued by darkness. The forest, once a haven of life and harmony, was overrun by a malevolent force that sought to corrupt and dominate nature. The spirits of the forest, desperate to restore balance, called upon the essence of two of their most revered creatures: the wolf and the badger.',
      'The wolf, symbolizing strength, leadership, and fierce loyalty, was known for its unparalleled hunting skills and protective nature. The badger, embodying tenacity, courage, and wisdom, was renowned for its unyielding spirit and deep connection to the earth. The spirits wove together the finest qualities of these animals, birthing the Wolfbadger, a creature destined to be the guardian of Eldergrove.',
    ],
  },
  {
    title: 'Appearance',
    content: [
      "The Wolfbadger stands as a majestic figure, its form a perfect blend of its progenitors. It possesses the powerful, muscular build and noble posture of a wolf, with short, sturdy legs that give it the resilience and grounding of a badger. Its fur is a striking combination of grey and white, interspersed with black and white patterns that run across its body like war paint. The face of the Wolfbadger is a harmonious blend of the wolf's sharp, predatory features and the badger's distinctive stripes, giving it an aura of both majesty and mystery. Its eyes are piercing and intelligent, glowing with an inner light that speaks of ancient wisdom and an unyielding spirit.",
    ],
  },
  {
    title: 'Role in the Forest',
    content: [
      'The Wolfbadger is more than just a guardian; it is a symbol of balance and harmony. It roams the forest with a silent grace, its presence commanding respect from all creatures. It is a fierce protector, confronting any threat with unmatched ferocity, yet it is also a noble creature, showing compassion and understanding to those who honor the ways of the forest.',
      "Legends speak of the Wolfbadger's ability to communicate with the forest spirits, seeking guidance and wisdom to maintain the delicate balance of life. It is said that when the Wolfbadger howls, its voice carries the prayers of the forest to the heavens, and when it digs its powerful claws into the earth, it connects with the very soul of the land.",
    ],
  },
];

export const AboutPage = () => {
  const getContentHtml = (content: string[]) => {
    return (
      <ContentWrapper>
        {content.map((text, index) => (
          <Par key={index}>{text}</Par>
        ))}
      </ContentWrapper>
    );
  };
  return (
    <Wrapper>
      <ContentWrapperTwo>
        {aboutContent.map(({ title, content }, index) => (
          <ContentWrapper>
            {title && <Heading>{title}</Heading>}
            {getContentHtml(content)}
          </ContentWrapper>
        ))}
        <div style={{ fontSize: '1px' }}>(end)</div>
      </ContentWrapperTwo>
    </Wrapper>
  );
};
