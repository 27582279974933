import styled from 'styled-components';

const Wrapper = styled.div`
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: center;
  font-size: 2rem;
  height: 100%;
  width: 100%;
  text-align: center;
`;

export const ErrorPage = () => {
  return (
    <Wrapper>
      <div>Oops!</div>
      <div> An unexpected error occurred...</div>
    </Wrapper>
  );
};
