import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

const Wrapper = styled.div`
  align-items: center;
  background-color: #424242;
  display: flex;
  flex-flow: row nowrap;
  height: 100vh;
  justify-content: flex-start;
  width: 100vw;
  max-width: 100vw;
  padding: 1rem 0;

  @media screen and (max-aspect-ratio: 1/1) {
    flex-flow: column nowrap;
    padding: 0;
  }
`;

const ContentWrapper = styled.div`
  align-items: center;
  background-color: #424242;
  display: flex;
  flex-flow: column nowrap;
  flex-grow: 1;
  height: 100%;
  justify-content: flex-start;
  padding-left: 150px;
  overflow-y: scroll;

  @media screen and (max-aspect-ratio: 1/1) {
    padding: 76px 0 1rem 0;
  }
`;

const MenuWrapper = styled.div`
  align-items: flex-start;
  background-color: #4f4f4f;
  display: flex;
  flex-flow: column nowrap;
  height: 100%;
  justify-content: flex-start;
  position: fixed;
  width: 150px;

  @media screen and (max-aspect-ratio: 1/1) {
    flex-flow: row nowrap;
    height: auto;
    width: 100%;
  }
`;

const MenuItem = styled(Link)`
  align-items: center;
  border-radius: 1.5rem;
  color: #cccccc;
  cursor: pointer;
  display: flex;
  font-size: 1.5rem;
  justify-content: flex-start;
  padding: 1rem;
  text-decoration: none;
  width: 100%;

  &:hover {
    background-color: #ff0000;
    background-color: #7a7a7a;
  }

  @media screen and (max-aspect-ratio: 1/1) {
    width: auto;
  }
`;

export const PageLayout = ({ children }: { children: React.ReactNode }) => {
  return (
    <Wrapper>
      <MenuWrapper>
        <MenuItem to={`/`}>Gallery</MenuItem>
        <MenuItem to={`/about`}>About</MenuItem>
      </MenuWrapper>
      <ContentWrapper>{children}</ContentWrapper>
    </Wrapper>
  );
};
