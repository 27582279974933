import { PageLayout } from '../components/shared/PageLayout';
import { Outlet } from 'react-router';

export const RootPage = () => {
  return (
    <PageLayout>
      <Outlet />
    </PageLayout>
  );
};
