import styled from 'styled-components';

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 1rem;
  font-size: 3rem;
  width: 100%;
  padding: 0 1rem;

  @media screen and (max-aspect-ratio: 1/1) {
    grid-template-columns: 1fr;
  }
`;

const Image = styled.img`
  width: 100%;
  max-width: 512px;
  height: auto;
`;

export const HomePage = () => {
  return (
    <Wrapper>
      <Image src='assets/CGPT-2.webp' />
      <Image src='assets/CGPT-1.webp' />
      <Image src='assets/CGPT-3.webp' />
      <Image src='assets/CGPT-4.webp' />
      <Image src='assets/head.jpg' />
      <Image src='assets/body-all-fours.jpeg' />
    </Wrapper>
  );
};
